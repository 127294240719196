<template>
  <div class="vip-money">
    <div class="contanier h">
      <div class="title">受到用户的喜爱</div>
      <div class="select">
        <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
          <el-radio-button label="month">每月</el-radio-button>
          <el-radio-button label="year">每年</el-radio-button>
          <el-radio-button label="never">终身</el-radio-button>
        </el-radio-group>
      </div>
      <div class="money">
        <div class="left" v-if="viptext.length >= 1">
          <div class="title-item">
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/util/images/index/icon_jc.png')"
              fit="contain"
            ></el-image>
            <h3>········ {{ viptext[0].title }} ········</h3>
          </div>
          <div class="vipinfo">
            <div class="viptitle">免费</div>
            <div v-html="viptext[0].content"></div>
            <el-button class="moneybtn" @click="login">现在就开始</el-button>
          </div>
        </div>
        <div class="center" v-if="viptext.length >= 2">
          <div class="title-item">
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/util/images/index/icon_sy.png')"
              fit="contain"
            ></el-image>
            <h3>········ {{ viptext[1].title }} ········</h3>
          </div>
          <div class="vipinfo">
            <div class="viptitle" v-show="tabPosition == 'month'">
              ¥ {{ viptext[1].price_m }}<span>/月</span>
            </div>

            <div class="viptitle" v-show="tabPosition == 'year'">
              ¥ {{ viptext[1].price_y }}<span>/年</span>
            </div>

            <div class="viptitle" v-show="tabPosition == 'never'">
              ¥ {{ viptext[1].price }}<span>/终身</span>
            </div>
            <div v-html="viptext[1].content"></div>
            <el-button class="moneybtn" @click="login">现在就开始</el-button>
          </div>
        </div>
        <div class="left" v-if="viptext.length >= 3">
          <div class="title-item">
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/util/images/index/icon_jg.png')"
              fit="contain"
            ></el-image>
            <h3>········ {{ viptext[2].title }} ········</h3>
          </div>
          <div class="vipinfo">
            <div class="viptitle" v-show="tabPosition == 'month'">
              ¥ {{ viptext[2].price_m }}<span>/月</span>
            </div>

            <div class="viptitle" v-show="tabPosition == 'year'">
              ¥ {{ viptext[2].price_y }}<span>/年</span>
            </div>

            <div class="viptitle" v-show="tabPosition == 'never'">
              ¥ {{ viptext[2].price }}<span>/终身</span>
            </div>
            <div v-html="viptext[2].content"></div>
            <el-button class="moneybtn" @click="login">现在就开始</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { index } from "../../api/userapi";
export default {
  name: "vip-money",
  props: {},
  components: {},

  data() {
    return {
      tabPosition: "month",
      viptext: []
    };
  },

  mounted() {
    this.getinfo();
  },

  methods: {
    login() {
      this.$router.push("/login");
    },
    getinfo() {
      index()
        .then(res => {
          this.viptext = res.goods_list;
        })
        .catch();
    }
  }
};
</script>

<style lang="less" scoped>
.money {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  .moneybtn {
    width: 100%;
    height: 50px;
    background: #eaeced;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
    border: 0px;
  }
  li {
    font-size: 14px;
    color: #333333;
    line-height: 26px;
  }
  ul {
    margin-bottom: 20px;
  }
  .viptitle {
    font-size: 48px;
    font-weight: bold;
    color: #0383e4;
    line-height: 48px;
    margin-top: 40px;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      color: #cfa980;
    }
  }
  .left {
    width: 350px;
    // height: 778px;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px rgba(8, 21, 66, 0.06);
    border-radius: 4px;
    border: 1px solid #d9dde1;
    padding: 40px;
    .title-item {
      height: 119px;
      border-bottom: 1px solid #d9dde1;
      text-align: center;
    }
    h3 {
      font-size: 20px;
      color: #0383e4;
      line-height: 20px;
      margin-top: 20px;
    }
  }
  .center {
    width: 350px;
    padding-bottom: 30px;
    // height: 778px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px rgba(8, 21, 66, 0.06);
    border-radius: 4px;
    border: 1px solid #d9dde1;
    text-align: center;
    .title-item {
      width: 350px;
      height: 160px;
      background: #3abd98;
      box-shadow: 0px 5px 20px 0px rgba(8, 21, 66, 0.06);
      border-radius: 4px 4px 0px 0px;
      padding: 40px;
    }
    h3 {
      font-size: 20px;
      color: #fff;
      line-height: 20px;
      margin-top: 20px;
    }
    .moneybtn {
      background: #0383e4;
      color: #ffffff;
    }
    .vipinfo {
      padding: 0 40px;
      text-align: left;
    }
  }
}
.select {
  display: flex;
  justify-content: center;
}
.vip-money {
  height: 1258px;
  background: #f3f6f8;
}
.h {
  height: 1258px;
}
.title {
  text-align: center;
  font-size: 30px;
  color: #000000;
  padding: 100px 0 50px 0;
}
/deep/.el-radio-button__inner {
  width: 68px;
  height: 50px;
  background: transparent;
  border: 0px;
  font-size: 14px;
  color: #000000;
  height: 50px;
  line-height: 50px;
  padding: 0;
  text-align: center;
  margin: 0 20px;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: 0px;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  width: 68px;
  height: 50px;
  background: #0383e4;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
}
</style>
